<template>
  <div class="about-page pd-y-20">
    <div class="flex flex-x-center e-width p-rel">
      <el-image src="/img/aboutus.jpg" style="width:1080px;height:257px" fit="cover" />
      <div class="p-abs fs-26 t-color-fff fwb flex w-full flex-x-center pd-t-30">
        <div class="flex-item-2 tac pd-l-50"></div>
        <div class="flex-item-3 tac">专业于分期场景</div>
      </div>
      <!-- <el-image v-if="tabIndex == 0 || tabIndex == 2" src="/img/aboutus.jpg" style="width:1080px;height:257px" fit="cover"/>
      <img v-else src="/img/wenhua.png" alt />-->
    </div>
    <div class="flex mg-t-20" style="width: 1080px; margin: 0 auto;border-radius: 0 0 10px 10px;">
      <div class="left">
        <div class="h-60 fs-18 l-title flex flex-y-center flex-x-center">
          <strong>{{ tabs[tabIndex].name }}</strong>
        </div>
        <div v-for="(item, key) in tabs" :key="key" :class="{ active: key == tabIndex }" @click="tabIndex = key"
          class="item h-45 cp flex flex-y-center flex-x-center t-a-03">{{ item.name }}</div>
      </div>
      <div class="right flex-item-1 mg-l-20 h-420">
        <template v-if="tabIndex == 0">
          <div class="flex pd-t-20">
            <div class="w-410 mg-r-20">
              <div class="fs-30 fwb" style="color: red">COMPANY PROFILE</div>
              <div class="fs-20 fwb pd-b-10" style="border-bottom: 1px solid #ccc">成都中金大通</div>
              <div class="mg-t-20">2011年开始为中国银联提供技术支持和营销推广合作服务</div>
              <div class="mg-t-10">2016年2月开始设计开发零零购平台1.0系统，8月上线运营</div>
              <div class="mg-t-10">2019年6月零零购全面升级为2.0系统，并开始投入全国生产运营</div>
              <div class="mg-t-10">2021年5月零零购全面升级为3.0系统，截止目前累计服务用户超100万，实现系统稳定运行，为银联零零购信用卡分期提供了有效的技术和服务保障</div>
            </div>
            <div class="flex-item-2 pd-t-90">
              <img src="/img/com.png" alt class="w-full" />
            </div>
          </div>
        </template>
        <div v-else-if="tabIndex == 1" class="pd-t-20 pd-r-20 fs-16">
          <div class="pd-b-8">不断倾听和满足用户需求，引导并超越用户需求，赢得用户尊敬；</div>
          <div class="pd-b-8">通过提升企业地位与品牌形象，使员工具有高度的企业荣誉感和自豪感，赢得员工尊敬；</div>
          <div class="pd-b-8">推动互联网行业的健康发展，与合作伙伴共同成长，赢得行业尊敬；</div>
          <div>注重企业责任，关爱社会、回馈社会，赢得社会尊敬。</div>
        </div>
        <div v-else>
          <div>
            <el-carousel :interval="4000" height="420px" arrow="never" type="card">
              <el-carousel-item v-for="(url, key) in srcList" :key="key">
                <el-image class="w-full h-full" :src="url" :preview-src-list="srcList" fit="contain"></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabIndex: 0,
      tabs: [
        {
          name: "关于我们",
          key: 0,
        },
        {
          name: "企业文化",
          key: 1,
        },
        {
          name: "资质证书",
          key: 3,
        },
      ],
      srcList: [
        "/img/lce/qs.jpg",
        "/img/lce/sbzc_all.jpg",
        "/img/lce/yyzz.jpg",
        "/img/lce/zzzs.jpg",
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.about-page {
  .left {
    border-radius: 10px 0 0 10px;
    width: 250px;
    background: #f1f1f1;
    .l-title {
      border-bottom: 1px solid #e1dede;
    }
    .item {
      &:hover,
      &.active {
        background: #dd2c34;
        color: #fff;
      }
    }
  }
}
</style>
